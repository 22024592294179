function staticMap() {
    const mapElem  = document.querySelector('.bing_static_map');

    if (!window.objLocations || !window.objLocations[0] || !mapElem) {
        return null;
    }

    const CENTER_POINT = Object.values(window.objLocations)[0].address;
    const ZOOM_LEVEL = 15;
    const BING_KEY = 'AoFyBUKdbUpGXKsx7nfbh5Mo44xHHQZoSLnF8ndn7HWE0i84hAJPsyMjn_XRzRaI';
    const MAP_SIZE = '1000,800';

    mapElem.src = `https://dev.virtualearth.net/REST/v1/Imagery/Map/Road/${CENTER_POINT}/${ZOOM_LEVEL}?mapSize=${MAP_SIZE}&pp=${CENTER_POINT};90&key=${BING_KEY}`;

    return mapElem;
}

export default staticMap;